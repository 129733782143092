<template>
    <v-dialog v-model="display" :width="dialogWidth">
        <template v-slot:activator="{ on }">
            <v-text-field v-bind="textFieldProps" :disabled="disabled" :loading="loading" :label="label"
                :value="selectedTime" :class="customClass" :outlined="outlined" :dense="dense"
                :hide-details="hideDetails" :single-line="singleLine" class="c-input-small" v-on="on" readonly>
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-time-picker ref="timer" class="v-time-picker-custom" format="24hr" v-model="time"
                    v-bind="timePickerProps" full-width locale="vi-vn" @click:minute="okHandler"></v-time-picker>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'


const DEFAULT_TIME = ''
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

export default {
    name: 'TimePickerDialog',
    model: {
        prop: ['value'],
        event: 'change'
    },
    props: {
        loading: {
            type: Boolean,
            default: () => false
        },
        dialogWidth: {
            type: Number,
            default: DEFAULT_DIALOG_WIDTH
        },
        timeFormat: {
            type: String,
            default: 'HH:mm'
        },
        clearText: {
            type: String,
            default: DEFAULT_CLEAR_TEXT
        },
        okText: {
            type: String,
            default: DEFAULT_OK_TEXT
        },
        textFieldProps: {
            type: Object,
            default: () => { }
        },
        timePickerProps: {
            type: Object,
            default: () => { }
        },
        value: {
            type: [Date, Number, String, Array, Object],
            default: () => null
        },
        label: {
            type: String,
            default: () => null
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        dense: {
            type: Boolean,
            default: () => true
        },
        hideDetails: {
            type: Boolean,
            default: () => true
        },
        singleLine: {
            type: Boolean,
            default: () => true
        },
        customClass: {
            type: String,
            default: () => null
        },
        minDate: {
            type: String,
            default: () => ''
        },
    },
    data() {
        return {
            display: false,
            activeTab: 0,
            time: DEFAULT_TIME
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        selectedTime() {
            if (this.time) {
                return this.time
            } else {
                return null
            }
        },
        dateSelected() {
            return !this.date
        }
    },
    methods: {
        init() {
            if (!this.value) {
                return false
            }

            this.time = moment(this.value, 'HH:mm:ss').format('HH:mm')
        },
        okHandler() {
            this.resetPicker()
            this.$emit('change', this.selectedTime)
        },
        clearHandler() {
            this.resetPicker()
            this.time = DEFAULT_TIME
            this.$emit('change', null)
        },
        resetPicker() {
            this.display = false
            this.activeTab = 0
            if (this.$refs.timer) {
                this.$refs.timer.selectingHour = true
            }
        },
        showTimePicker() {
            this.activeTab = 1
        }
    },
}
</script>