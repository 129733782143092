<template>
    <v-dialog v-model="display" :width="dialogWidth">
        <template v-slot:activator="{ on }">
            <v-text-field v-bind="textFieldProps" :disabled="disabled" :loading="loading" :label="label"
                :value="selectedDates" :class="customClass" :outlined="outlined" :dense="dense"
                :hide-details="hideDetails" :single-line="singleLine" class="c-input-small" v-on="on" readonly>
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-date-picker
                    v-model="dates"
                    multiple
                    full-width
                    locale="vi-vn"
                    v-bind="datePickerProps"
                ></v-date-picker>
            </v-card-text>
        
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="actions" :parent="this">
                    <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
                    <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'


const DEFAULT_DATES = []
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

export default {
    name: 'DatePickerMultipleDialog',
    model: {
        prop: ['value'],
        event: 'change'
    },
    props: {
        loading: {
            type: Boolean,
            default: () => false
        },
        dialogWidth: {
            type: Number,
            default: DEFAULT_DIALOG_WIDTH
        },
        clearText: {
            type: String,
            default: DEFAULT_CLEAR_TEXT
        },
        okText: {
            type: String,
            default: DEFAULT_OK_TEXT
        },
        textFieldProps: {
            type: Object,
            default: () => { }
        },
        datePickerProps: {
            type: Object,
            default: () => { }
        },
        value: {
            type: [Date, Number, String, Array, Object],
            default: () => null
        },
        label: {
            type: String,
            default: () => null
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        dense: {
            type: Boolean,
            default: () => true
        },
        hideDetails: {
            type: Boolean,
            default: () => true
        },
        singleLine: {
            type: Boolean,
            default: () => true
        },
        customClass: {
            type: String,
            default: () => null
        },
        minDate: {
            type: String,
            default: () => ''
        },
    },
    data() {
        return {
            display: false,
            dates: DEFAULT_DATES
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        selectedDates() {
            if (!this.dates || this.dates.length === 0) {
                return null
            }
            return this.dates.map(date => (moment(date).format('DD/MM/YYYY'))).join(' - ')
        },
    },
    methods: {
        init() {
            if (!this.value || this.value.length === 0) {
                return false
            }
        },
        okHandler() {
            this.resetPicker()
            this.$emit('change', this.dates)
        },
        clearHandler() {
            this.resetPicker()
            this.dates = DEFAULT_DATES
            this.$emit('change', this.dates)
        },
        resetPicker() {
            this.display = false
        },
    },
}
</script>