<template>
  <div>
    <!-- eslint-disable -->
    <v-simple-table fixed-header :height="tableHeight" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" v-for="(column, key) in columns" :key="`${key}_${column.name}`"
              v-if="isColumnShow(column)">
              <div :class="column.class" v-if="column.type === ''">{{ column.label }}</div>
              <div :class="column.class" v-else-if="column.type === 'button'">
                <v-btn :color="column.headerActionColor" small @click="emitAction(column.headerAction, {})">
                  {{ column.headerActionLabel }}
                </v-btn>
              </div>
              <component v-else :is="column.type" :label="column.label" :placeholder="column.placeholder"
                :name="column.name" :sort-name="column.sortName" :options="column.options" :has-sort="column.hasSort"
                :class="column.class" :filters="filters" :default-value="column.defaultValue" :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" v-bind="column.params">
              </component>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`i_${index}_${item.id}`" class="text-center">
            <td v-for="(column, key) in columns" :key="`v_${key}_${column.key}`" v-if="isColumnShow(column)">
              <div v-if="column.component === 'DeliveryTracking'">
                <DeliveryTracking :order="item" />
              </div>
              <div v-else-if="column.component === 'OrderTracking'">
                <OrderTracking :tracking-id="item[column.key]" />
              </div>
              <div v-else-if="column.component === 'Image'">
                <ImageViewer v-if="item[column.key]" :url="item[column.key]" />
                <span
                  v-else-if="column.action && checkPermission(column.actionPermissions) && checkModule(column.actionModules)"
                  class="text-decoration-underline info--text cursor-pointer" @click="emitAction(column.action, item)">
                  {{ column.actionText }}
                </span>
              </div>
              <div v-else-if="column.component === 'ImageMultiple'">
                <ImageMultipleViewer v-if="item[column.key]" :images="item[column.key]" />
              </div>
              <div v-else-if="['date-range-filter'].includes(column.type)">
                {{ item[column.key] ? (column.dateOnly ? formatDateTime(item[column.key], 'DD/MM/YYYY') : formatDateTime(item[column.key])) : '' }}
              </div>
              <div v-else-if="['input-filter-from-to'].includes(column.type)">
                <span
                  v-if="column.action && checkPermission(column.actionPermissions) && checkModule(column.actionModules)"
                  class="text-decoration-underline info--text cursor-pointer" @click="emitAction(column.action, item)">
                  {{ item[column.key] || item[column.key] === 0 ? formatNumber(item[column.key]) : '' }}
                </span>
                <span v-else>
                  {{ item[column.key] || item[column.key] === 0 ? formatNumber(item[column.key]) : '' }}
                </span>
              </div>
              <div v-else-if="['button'].includes(column.type)">
                <v-btn :color="column.actionColor" x-small @click="emitAction(column.action, item)">
                  {{ column.actionLabel }}
                </v-btn>
              </div>
              <div v-else-if="['select-filter'].includes(column.type)">
                {{ getTxtFromOption(column.options, item[column.key]) }}
              </div>
              <div v-else>
                <div
                  v-if="column.action && checkPermission(column.actionPermissions) && checkModule(column.actionModules)"
                  v-html="item[column.key]" class="text-decoration-underline info--text cursor-pointer"
                  @click="emitAction(column.action, item)"></div>
                <div v-else v-html="item[column.key]"></div>
              </div>
              <template v-if="column.buttonActions && column.buttonActions.length > 0">
                <div v-for="buttonAction in column.buttonActions"
                  v-if="checkModule(buttonAction.modules) && checkPermission(buttonAction.permissions)">
                  <v-btn v-if="!buttonAction.allowKey || item[buttonAction.allowKey]" x-small :color="buttonAction.color"
                    :class="buttonAction.class" @click="emitAction(buttonAction.action, item)">
                    {{ buttonAction.text }}
                  </v-btn>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <div class="d-flex justify-start align-center" style="min-height: 40px">
          <v-btn color="info" @click="downloadExcel" v-if="apiDownloadUrl" class="mr-2">
            <v-icon>mdi-download</v-icon>
            {{ $t('labels.excel') }}
          </v-btn>
          <div class="d-flex flex-wrap table-footer-count" v-if="sums && sums.length > 0">
            <div class="mr-2" v-for="sItem in sums">
              {{ sItem.text }}:
              <b>{{ formatNumber(sum[sItem.key]) }}</b>
            </div>
          </div>
          <component v-if="countComponent" :is="countComponent" :counts="counts"></component>
          <div v-if="!countComponent && (!sums || sums.length === 0) && total" class="d-flex flex-wrap table-footer-count">
            <div class="mr-2">
              {{ $t('labels.total') }}:
              <b>{{ formatNumber(total) }}</b>
              {{ $t('labels.row') }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-pagination v-if="totalPage > 1" v-model="page" :length="totalPage" :total-visible="5"></v-pagination>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex align-center justify-end" style="min-height: 40px">
          <div>
            <template v-for="footerAction in footerActions">
              <v-btn
                v-if="footerAction.action && checkPermission(footerAction.permissions) && checkModule(footerAction.modules)"
                :color="footerAction.color" :class="footerAction.class" @click="emitAction(footerAction.action, filters)">
                {{ footerAction.text }}
              </v-btn>
              <a v-else-if="footerAction.link && checkPermission(footerAction.permissions) && checkModule(footerAction.modules)"
                :href="footerAction.link" :class="footerAction.class">
                {{ footerAction.text }}
              </a>
            </template>
          </div>
          <div v-if="isPageShow && totalPage > 1">
            <v-text-field class="c-input-xs mr-2" dense outlined hide-details v-model.number="page"
              :label="$t('labels.page')" :placeholder="$t('labels.page')" style="width: 55px"></v-text-field>
          </div>
          <div>
            <v-autocomplete v-if="viewModes && viewModes.length > 0" class="c-input-small" v-model="viewMode"
              :items="viewModes" outlined dense hide-details :label="$t('labels.view_mode')"
              @change="onModeChange"></v-autocomplete>
          </div>
          <div class="pl-2" v-if="customColumns.length > 0">
            <v-menu top offset-y :close-on-content-click="false" min-width="200px" @input="onMenuToggle">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" class="cursor-pointer" v-bind="attrs" v-on="on">mdi-dots-grid</v-icon>
              </template>

              <v-list dense>
                <v-list-item>{{ $t('labels.display') }}</v-list-item>
                <v-list-item v-for="(item, index) in customColumns" :key="`c_${index}_${item.name}`"
                  style="min-height: 30px;" v-if="checkPermission(item.permissions)">
                  <v-list-item-title @click="toggleCustomeColumn(item)" class="cursor-pointer font-weight-regular">
                    <div class="d-flex align-center">
                      <v-icon v-if="selectedCustomColumns.includes(item.key)" small
                        class="primary--text">mdi-checkbox-marked</v-icon>
                      <v-icon v-else small>mdi-checkbox-blank-outline</v-icon>
                      <span class="ml-2">{{ item.label }}</span>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: 'CustomTable',
  components: {
    SelectWarehouse: () => import('@/components/table/SelectWarehouse'),
    SelectPos: () => import('@/components/table/SelectPos'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    SelectEmployee: () => import('@/components/table/SelectEmployee'),
    SelectDeliveryCompany: () => import('@/components/table/SelectDeliveryCompany'),
    SelectEMarket: () => import('@/components/common/SelectEMarket'),
    SelectEMarketShop: () => import('@/components/common/SelectEMarketShop'),
    ImageViewer: () => import("@/components/common/ImageViewer"),
    DeliveryTracking: () => import('@/components/common/DeliveryTracking'),
    OrderTracking: () => import('@/components/common/OrderTracking'),
  },
  props: {
    tableHeight: {
      type: String,
      default: () => 'calc(100vh - 145px)'
    },
    columns: {
      type: Array,
      default: () => []
    },
    viewModes: {
      type: Array,
      default: () => []
    },
    sums: {
      type: Array,
      default: () => []
    },
    defaultViewMode: {
      type: String,
      default: () => ''
    },
    apiUrl: {
      type: String,
      default: () => ''
    },
    apiDownloadUrl: {
      type: String,
      default: () => ''
    },
    downloadFileName: {
      type: String,
      default: () => 'download.xlsx'
    },
    customView: {
      type: Number,
      default: () => 0
    },
    footerActions: {
      type: Array,
      default: () => []
    },
    isPageShow: {
      type: Boolean,
      default: () => true
    },
    countComponent: {
      type: String,
      default: () => null
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    page: 1,
    sum: {},
    counts: {},
    total: null,
    totalPage: 1,
    filters: {},
    viewMode: null,
    selectedCustomColumns: [],
  }),
  computed: {
    customColumns() {
      return [...this.columns].filter(col => !col.required && (!this.viewMode || !col.showModes || col.showModes.length === 0 || col.showModes.includes(this.viewMode)))
    },
    isColumnShow() {
      return column => (!column.showModes || column.showModes.length === 0 || column.showModes.includes(this.viewMode)) && (column.required || this.selectedCustomColumns.includes(column.key)) && (!column.permissions || column.permissions.length === 0 || this.checkPermission(column.permissions))
    }
  },
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  mounted() {
    if (this.defaultViewMode) {
      this.viewMode = this.defaultViewMode
    }
    // this.getSavedColumns()
    this.getList()
  },
  methods: {
    onModeChange() {
      this.page = 1
      this.filter = {}
      this.getList()
    },

    onMenuToggle(isShow) {
      if (!isShow) {
        this.getList()
      }
    },

    getList: debounce(function () {
      httpClient.post(this.apiUrl, { ...this.filters, viewMode: this.viewMode, page: this.page }).then(({ data }) => {
        this.totalPage = data.totalPage
        this.total = data.total || null
        this.items = [...data.rows]
        if (data.views) {
          this.selectedCustomColumns = [...data.views]
        }
        if (data.sum) {
          this.sum = { ...data.sum }
        }
        if (data.counts) {
          this.counts = { ...data.counts }
        }
      })
    }, 1000),

    emitAction(action, item) {
      this.$emit(action, item)
    },

    async getSavedColumns() {
      const res = await this.getCustomView({ type: this.customView })
      this.selectedCustomColumns = [...res]
    },

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      const filename = this.downloadFileName

      try {
        await this.downloadExcelFile(this.apiDownloadUrl, { ...this.filters, viewMode: this.viewMode }, filename)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    toggleCustomeColumn(item) {
      let status = false
      console.log(item.key, [...this.selectedCustomColumns])
      if (!this.selectedCustomColumns.includes(item.key)) {
        this.selectedCustomColumns.push(item.key)
        status = true
      } else {
        this.selectedCustomColumns = [...this.selectedCustomColumns].filter(cl => cl !== item.key)
      }
      console.log(item.key, [...this.selectedCustomColumns])
      this.saveCustomView({
        type: this.customView,
        name: item.key,
        status
      })
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort }
    },

    getTxtFromOption(options, value) {
      const item = [...options].find(opt => opt.value == value)
      return item && item.text || value
    }
  }
}
</script>
