<template>
    <div>
        <v-text-field
                ref="inputRef"
                class="c-input-small"
                :value="value"
                :label="label"
                :disabled="disabled"
                :class="customClass"
                :outlined="outlined"
                :dense="dense"
                :hide-details="hideDetails"
                :single-line="singleLine"
                append-icon="mdi-qrcode-scan"
                @input="updateValue"
                @keyup.enter="keyupEnter"
                @click:append="showQRCodeScan"
        ></v-text-field>

        <v-dialog v-model="qrScanDialog" max-width="400px">
            <QRCodeScanner
                    v-if="qrScanDialog"
                    name="code"
                    :auto-close="true"
                    @close="hideQRCodeScan"
                    @onScanned="onQRScanned"
            />
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "InputQrScanModel",
    components: {
        QRCodeScanner: () => import('@/components/common/QRCodeScanner'),
    },
    props: {
        value: {
            type: [Number, String],
            default: () => null
        },
        label: {
            type: String,
            default: () => 'Code'
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        singleLine: {
            type: Boolean,
            default: () => false
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        dense: {
            type: Boolean,
            default: () => true
        },
        hideDetails: {
            type: Boolean,
            default: () => true
        },
        autoClose: {
            type: Boolean,
            default: () => true
        },
        customClass: {
            type: String,
            default: () => null
        }
    },
    data: () => ({
        qrScanDialog: false
    }),
    model: {
        prop: ['value'],
        event: 'change'
    },
    methods: {
        showQRCodeScan() {
            this.qrScanDialog = true
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
        },
        onQRScanned(filter) {
            const val = filter.value
            this.updateValue(val)
            this.$emit('keyupEnter', val)
        },
        keyupEnter($el) {
            const val = $el.target.value
            this.$emit('keyupEnter', val)
        },
        updateValue(val) {
            this.$emit('change', val)
            if (!this.autoClose && this.qrScanDialog) {
                setTimeout(() => {
                    this.showQRCodeScan()
                }, 10)
            }
        },
        focusInput() {
            this.$refs.inputRef.focus()
        }
    }
}
</script>

<style scoped>

</style>